<template>
    <v-dialog v-model="openMode" max-width="1000px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <!-- SIM Number Field -->
                            <v-col v-if="formTitle === 'ערוך סים'" cols="12" md="6">
                                <v-text-field v-model="itemEdited.number" label="מספר סים"
                                    :rules="[rules.required, rules.onlyDigits]" outlined dense></v-text-field>
                            </v-col>

                            <!-- Company Select -->
                            <v-col cols="12" md="6">
                                <v-select v-model="itemEdited.company" :items="companies" item-text="name"
                                :rules="[v => !!v || 'שדה חובה']" required  item-value="id" label="חברה" outlined dense></v-select>
                            </v-col>

                            <!-- Seller ID Select -->
                            <v-col cols="12" md="6">
                                <v-select v-model="itemEdited.seller_id._id" :items="sellersList" item-text="name"
                                :rules="[v => !!v || 'שדה חובה']" required   item-value="_id" label="משווק" outlined dense></v-select>
                            </v-col>

                            <!-- Status Select -->
                            <v-col cols="12" md="6">
                                <v-select v-model="itemEdited.status" :items="statuses" item-text="name" item-value="id"
                                    label="סטטוס (אופציונלי)" outlined dense></v-select>
                            </v-col>

                            <!-- Multi-line TextArea (Only for "Add New") -->
                            <v-col v-if="formTitle !== 'ערוך סים'" cols="6">
                                <v-textarea  class="big-textarea"  v-model="sims" label="הכנס מספרי סים (שורה נפרדת לכל מספר)" outlined dense rows="10"
                                    :rules="[rules.required, rules.onlyDigitsTextArea, rules.maxLineLength]"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text
                                @click="formTitle === 'ערוך סים' ? saveEditedItem() : saveNewItem()">
                                שמור
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!-- Progress Loader -->
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';
import SharedFunctions from "@/shared/SharedFunctions";

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            number: "",
            company: "",
            seller_id: { _id: "", name: "" },
            status: true
        },
        sims: "",
        sellersList: [],
        companies: [{ id: "partner", name: "פרטנר" }, { id: "hotMobile", name: "הוט מובייל" }, { id: "clickCallMobile", name: "קליקול מובייל" }],
        statuses: [{ id: "free", name: "פנוי" }, { id: "used", name: "בשימוש" }],
        rules: {
            required: v => !!v || "שדה חובה",
            onlyDigits: v => /^\d*$/.test(v) || "ניתן להזין רק ספרות",
            onlyDigitsTextArea: v => v.split("\n").every(line => /^\d*$/.test(line)) || "כל שורה חייבת להכיל רק ספרות",
            maxLineLength: v => v.split("\n").every(line => line.length <= 21) || "כל שורה יכולה להכיל עד 21 תווים"
        }
    }),

    methods: {
        async saveNewItem() {
            if (this.$refs.form.validate()) {
                const jsonBody = JSON.stringify({ sims: this.sims, belongToSellerId: this.itemEdited.seller_id._id, company: this.itemEdited.company });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/sims/create_sims";
                    const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("simsCreated");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saving new sim: " + error, "red");
                }
            }
        },

        async saveEditedItem() {
            if (this.$refs.form.validate()) {
                const jsonBody = JSON.stringify({ sim: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/sims/edit_sim";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", jsonBody, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("simEdited", this.itemEdited, "הסים עודכן בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saving edited sim: " + error, "red");
                }
            }
        },

        async getSellers() {
            const sellersList = await SharedFunctions.getSellersList();
            if (!sellersList) {
               return this.showSnackBar("שגיאה בקבלת רשימת משווקים", "red");
            }
            this.sellersList = sellersList;
        },

        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true;
        }
    },

    computed: {
        openMode: {
            get() {
                return this.value;
            },
            set(v) {
                return this.$emit('input', v);
            }
        }
    },

    mounted() {
        if (this.formTitle === "ערוך סים") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        }
        this.getSellers();
    }
}
</script>

<style scoped>
/* Adjust field spacing */
.v-text-field,
.v-select,
.v-textarea {
    margin-bottom: 12px;
}

.big-textarea ::v-deep textarea {
    padding-top: 10px;
    font-size: 20px !important;  /* Increase font size */
    font-weight: bold;  /* Make text bold */
    text-align: center; /* Center text horizontally */
}
</style>
