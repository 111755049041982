<template>
  <v-container fluid class="reset-container">
    <v-row class="fill-height justify-center align-center">
      <v-col cols="12" sm="8" md="6" lg="5">
        <v-card class="reset-card" flat>
          <!-- Lock Icon -->
          <div class="lock-icon-container mb-6">
            <v-icon size="48" color="#0d2c6d">mdi-lock-reset</v-icon>
          </div>

          <!-- Title -->
          <h1 class="text-h5 font-weight-bold text-center mb-2 primary-text">סים לחומרא</h1>
          <h1 class="text-h4 font-weight-bold text-center mb-2 primary-text">איפוס סיסמה</h1>
          <p class="text-subtitle-1 text-center text-medium-emphasis mb-8">
            אנא הזן את הסיסמה החדשה שלך
          </p>

          <!-- Reset Form -->
          <v-form @submit.prevent="update" ref="form">
            <v-text-field
              v-model="ressetPass.newPassword"
              label="סיסמה חדשה"
              :type="showPassword ? 'text' : 'password'"
              :rules="[
                v => !!v || 'שדה חובה',
                v => v.length >= 9 || 'הסיסמה חייבת להכיל לפחות 9 תווים'
              ]"
              outlined
              dense
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              prepend-inner-icon="mdi-lock"
              class="mb-4 custom-input"
              required
              dir="rtl"
              color="#0d2c6d"
              background-color="rgba(13, 44, 109, 0.02)"
            ></v-text-field>

            <v-text-field
              v-model="ressetPass.againPassword"
              label="אימות סיסמה"
              :type="showConfirmPassword ? 'text' : 'password'"
              :rules="[
                v => !!v || 'שדה חובה',
                v => v === ressetPass.newPassword || 'הסיסמאות אינן תואמות'
              ]"
              outlined
              dense
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirmPassword = !showConfirmPassword"
              prepend-inner-icon="mdi-lock-check"
              class="mb-6 custom-input"
              required
              dir="rtl"
              color="#0d2c6d"
              background-color="rgba(13, 44, 109, 0.02)"
            ></v-text-field>

            <!-- Submit Button -->
            <v-btn
              block
              height="44"
              :loading="showSpinner"
              :disabled="showSpinner"
              type="submit"
              class="submit-button"
              elevation="2"
            >
              <v-icon left class="mr-2">mdi-check</v-icon>
              שמור סיסמה חדשה
            </v-btn>
          </v-form>

          <!-- Password Requirements -->
          <div class="password-requirements mt-6">
            <p class="text-subtitle-2 text-medium-emphasis mb-2">דרישות הסיסמה:</p>
            <div class="requirement" :class="{ 'requirement-met': ressetPass.newPassword.length >= 9 }">
              <v-icon small class="mr-2">{{ ressetPass.newPassword.length >= 9 ? 'mdi-check-circle' : 'mdi-circle-outline' }}</v-icon>
              מינימום 9 אותיות ומספרים
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColorBt"
      :timeout="5000"
      elevation="24"
      rounded="pill"
    >
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
  name: 'ResetPasswordView',
  
  data: () => ({
    showSpinner: false,
    snackbar: false,
    snackbarColorBt: "success",
    snacbarText: "",
    showPassword: false,
    showConfirmPassword: false,
    ressetPass: {
      newPassword: "",
      againPassword: "",
    },
  }),

  methods: {
    async update() {
      if (!this.$refs.form.validate()) return;

      this.showSpinner = true;
      const token = this.$route.params.token;
      const MyJSON = JSON.stringify({ newPassword: this.ressetPass.newPassword });

      try {
        const api = process.env.VUE_APP_BASE_URL + "/auth/resetPassword";
        const res = await fetch(api, ApiServices.requestOptions("PUT", MyJSON, token));
        
        if (res.status >= 400) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה בשמירת הסיסמה: " + jsonObject.message, "error");
        } else if (res.status === 200) {
          this.showSnackBar("הסיסמה שונתה בהצלחה", "success");
          await this.sleep(1500);
          window.location.pathname = `/`;
        }
      } catch (error) {
        this.showSnackBar("שגיאה בשמירת הסיסמה: " + error, "error");
      } finally {
        this.showSpinner = false;
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
}
</script>

<style scoped>
.reset-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc 0%, #f0f7ff 100%);
}

.reset-card {
  width: 100%;
  max-width: 420px;
  padding: 2.5rem;
  margin: 2rem auto;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 24px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 24px rgba(13, 44, 109, 0.08);
}

.lock-icon-container {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(13, 44, 109, 0.05) 0%, rgba(13, 44, 109, 0.1) 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.08);
}

.primary-text {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Custom Input Styling */
.custom-input {
  transition: all 0.3s ease;
}

.custom-input >>> .v-input__slot {
  border: 1px solid rgba(13, 44, 109, 0.1) !important;
  transition: all 0.3s ease;
}

.custom-input >>> .v-input__slot:hover {
  border-color: rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.03);
}

.custom-input.v-input--is-focused >>> .v-input__slot {
  border-color: #0d2c6d !important;
  box-shadow: 0 0 0 1px rgba(13, 44, 109, 0.2) !important;
  background: rgba(13, 44, 109, 0.02);
}

/* Submit Button */
.submit-button {
  background: linear-gradient(135deg, #0d2c6d 0%, #1a4ba5 100%) !important;
  color: white !important;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.95;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(13, 44, 109, 0.2) !important;
}

/* Password Requirements */
.password-requirements {
  padding: 1rem;
  background: rgba(13, 44, 109, 0.02);
  border-radius: 12px;
  border: 1px solid rgba(13, 44, 109, 0.1);
}

.requirement {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  transition: all 0.3s ease;
}

.requirement-met {
  color: #0d2c6d;
}

.requirement-met .v-icon {
  color: #0d2c6d !important;
}

/* RTL Support */
.v-application {
  direction: rtl;
}

.v-text-field >>> .v-input__prepend-inner {
  margin-right: 0;
  margin-left: 8px;
}

.v-text-field >>> .v-input__append-inner {
  margin-left: 0;
  margin-right: 8px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .reset-card {
    margin: 1rem;
    padding: 1.5rem;
  }
}
</style>