<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">האם אתה בטוח שברצונך למחוק את הסים?</v-card-title>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#3F51B5" text @click="closeDelete">ביטול</v-btn>
        <v-btn color="#3F51B5" text @click="deleteItem">אישור</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  props: {
    item: Object,
    value: { type: Boolean, default: false },

  },
  components: {
    SnackBar,
  },
  data: () => ({
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),

  methods: {
    async deleteItem() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const myJSON = JSON.stringify({ item: this.item});

        let api = process.env.VUE_APP_BASE_URL + "/sims/delete_sim";
        const res = await fetch(api, ApiServices.requestOptions("DELETE", myJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status >= 400) {
          this.showSnackBar("שגיאה במחיקת הסים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.$emit("itemDeleted", this.item, "הסים נמחק בהצלחה!", "green");
          this.openMode = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error delete item: " + error, "red");
      }
    },
    closeDelete() {
      this.openMode = false;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
}
</script>
<style></style>
  