<template>
    <div class="tickets-list-container">
        <!-- Header -->
        <v-app-bar flat color="#dae1eb" class="mb-4 header-bar" elevation="2">
            <v-container fluid>
                <v-row class="align-center">
                    <v-col cols="6" class="d-flex justify-end">
                        <span style="font-size: large; color: #0d2c6d;">ניהול פניות</span>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">
                        <div class="stats-chip">
                            <span class="font-weight-medium">סה"כ פניות:</span>
                            <span class="font-weight-bold ml-1">{{ totalTickets }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>

        <ProgressShow v-if="progressShow" v-model="progressShow"></ProgressShow>

        <v-container style="max-width: 1400px;">
            <!-- Filters Section -->
            <v-card class="mb-6" flat>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="חיפוש פניות" outlined
                                dense clearable @keyup.enter="getSearchTickets" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <v-select v-model="filterType" :items="typesList" item-text="hebrewName" item-value="value"
                                outlined dense hide-details label="סינון לפי סטטוס"></v-select>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-select v-model="itemsPerPage" :items="[20, 50, 100, 500, 1000]" outlined dense
                                hide-details label="פריטים בעמוד"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- Tickets List -->
            <v-card class="tickets-list" elevation="0">
                <template v-if="tickets.length">
                    <div v-for="(ticket, index) in tickets" :key="ticket._id" class="mb-3">
                        <v-hover v-slot="{ hover }">
                            <v-card :elevation="hover ? 3 : 1" :class="['ticket-card', {
                                'unread': ticket.responses[ticket.responses.length - 1].read_at ===
                                    null &&
                                    ticket.responses[ticket.responses.length - 1].responder_id.role
                                    !== userRole, 'closed': ticket.status === 'close'
                            }]" @click="navigateToTicket(ticket._id)">
                                <v-card-text>
                                    <v-row align="center" no-gutters>
                                        <v-col cols="12" sm="8">
                                            <div class="d-flex align-center">
                                                <div class="ticket-number ml-4">{{ calculatePosition(index) }}</div>
                                                <div>
                                                    <div class="d-flex align-center mb-2">
                                                        <v-chip small label class="ml-2"
                                                            :color="ticket.status === 'close' ? 'grey' : 'primary'">
                                                            #{{ ticket.integer_id }}
                                                        </v-chip>
                                                        <h3 class="subtitle-1 font-weight-bold mb-0">{{ ticket.subject
                                                            }}
                                                        </h3>
                                                    </div>
                                                    <p class="mb-1 grey--text text--darken-1 body-2">
                                                        {{ ticket.responses[ticket.responses.length -
                                                            1].message.slice(0, 150) }}...
                                                    </p>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="text-left">
                                            <div class="d-flex flex-column align-end">
                                                <span class="caption grey--text">{{ new
                                                    Date(ticket.createdAt).toLocaleString() }}</span>
                                                <div class="mt-2">
                                                    <v-chip x-small
                                                        :color="ticket.status === 'close' ? 'grey' : 'primary'"
                                                        text-color="white" class="ml-2">
                                                        {{ ticket.status === 'close' ? 'סגור' : 'פתוח' }}
                                                    </v-chip>
                                                    <v-chip x-small :color="ticket.responses[ticket.responses.length - 1].read_at ===
                                                        null &&
                                                        ticket.responses[ticket.responses.length - 1].responder_id.role
                                                        !== userRole ? 'warning' : 'success'" text-color="white">
                                                        {{ ticket.responses[ticket.responses.length - 1].read_at ===
                                                            null &&
                                                            ticket.responses[ticket.responses.length - 1].responder_id.role
                                                            !== userRole ? 'לא נקרא' : 'נקרא' }}
                                                    </v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </div>
                </template>
                <v-card-text v-else class="text-center py-8">
                    <v-icon size="64" color="grey lighten-1">mdi-ticket-outline</v-icon>
                    <div class="mt-2 grey--text">לא נמצאו פניות</div>
                </v-card-text>
            </v-card>

            <!-- Pagination -->
            <div class="text-center mt-6">
                <v-pagination v-model="currentPage" :length="Math.ceil(totalTickets / itemsPerPage)" :total-visible="7"
                    circle></v-pagination>
            </div>
        </v-container>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>

<script>
import ProgressShow from '@/components/widgets/progressShow.vue'
import Auth from '../../services/auth.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import { mapState } from 'vuex'

export default {
    name: 'TicketsListComp',
    components: {
        ProgressShow,
        SnackBar
    },
    data() {
        return {
            tickets: [],
            progressShow: false,
            totalTickets: 0,
            currentPage: 1,
            itemsPerPage: 20,
            snackbar: false,
            filter: "",
            snackbarColorBt: "success",
            snacbarText: "",
            filterType: "all",
            typesList: [
                { hebrewName: "הצג הכל", value: "all" },
                { hebrewName: "לא נקראו", value: "unread" },
                { hebrewName: "נסגרו", value: "close" }
            ],
            search: "",
            userRole: Auth.getUserRole(),
        }
    },
    computed: {
        ...mapState({
            newTicket: state => state.newTicket,
            newResponse: state => state.newResponse,
            ticketId: state => state.ticketId,
        }),
        isAdmin() {
            return Auth.getUserRole() === 'admin'
        },
    },
    watch: {
        newTicket(newValue) {
            this.tickets.unshift(newValue)
        },
        newResponse(newValue) {
            const ticket = this.tickets.findIndex((ticket) => ticket._id === this.ticketId)
            this.tickets[ticket].responses.push(newValue)
        },
        itemsPerPage() {
            this.getTicketsList()
        },
        currentPage() {
            this.getTicketsList()
        },
        filterType() {
            this.getTicketsList()
        }
    },
    methods: {
        getUserRole() {
            return Auth.getUserRole()
        },
        showSnackBar(message, color) {
            this.snacbarText = message
            this.snackbarColorBt = color
            this.snackbar = true
        },
        async getTicketsList() {
            try {
                this.progressShow = true
                let token = localStorage.getItem("token")
                const api = `${process.env.VUE_APP_BASE_URL}/tickets/get_tickets_list?page=${this.currentPage}&limit=${this.itemsPerPage}&filter=${this.filterType}`
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token))
                const jsonObject = await res.json()

                if (res.status >= 400) {
                    this.progressShow = false
                    this.showSnackBar("שגיאה בקבלת נתונים", "error")
                } else if (res.status === 200) {
                    this.tickets = jsonObject.tickets
                    this.totalTickets = jsonObject.totalTickets
                }
            } catch (error) {
                this.showSnackBar("שגיאה בקבלת נתונים: " + error, "error")
            } finally {
                this.progressShow = false
            }
        },
        async getSearchTickets() {
            if (!this.search) {
                this.getTicketsList()
                return
            }

            try {
                this.progressShow = true
                let token = localStorage.getItem("token")
                const JsonBody = JSON.stringify({ content: this.search.trim(), filter: this.filterType })
                let api = process.env.VUE_APP_BASE_URL + "/tickets/get_search_tickets"
                const res = await fetch(api, ApiServices.requestOptions("POST", JsonBody, token))
                const jsonObject = await res.json()

                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "error")
                } else if (res.status === 200) {
                    this.tickets = jsonObject.tickets
                    this.totalTickets = jsonObject.totalTickets
                }
            } catch (error) {
                this.showSnackBar("שגיאה בחיפוש: " + error, "error")
            } finally {
                this.progressShow = false
            }
        },
        navigateToTicket(ticketId) {
            this.$router.push({ name: 'TicketDetails', params: { id: ticketId } })
        },
        calculatePosition(index) {
            return (this.currentPage - 1) * this.itemsPerPage + index + 1
        },
    },
    mounted() {
        this.getTicketsList()
    },
}
</script>

<style scoped>
.tickets-list-container {
    background-color: #f5f7fa;
    min-height: 100vh;
}

.header-bar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.stats-chip {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px 16px;
    border-radius: 8px;
}

.ticket-card {
    transition: all 0.3s ease;
    border: 1px solid #e0e0e0;
    cursor: pointer;
}

.ticket-card.unread {
    background-color: #f5f5f5 !important;
    border-right: 4px solid #1976d2;
}

.ticket-card.closed {
    background-color: #f5f5f5 !important;
    border-right: 4px solid #9e9e9e;
}

.ticket-number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f5f7fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #1976d2;
}

.v-card--hover {
    transition: all 0.3s ease;
}
</style>