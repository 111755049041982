<template>
  <div class="ticket-detail-container">
    <!-- Header -->
    <v-app-bar flat color="primary darken-2" dark class="header-bar" elevation="3">
      <v-container>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-btn icon @click="$router.go(-1)" class="mr-3">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <div>
              <h1 class="text-h5 font-weight-bold mb-1">פרטי פנייה</h1>
              <div class="d-flex align-center">
                <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                <span class="caption">נוצר ב-{{ new Date(ticket?.createdAt).toLocaleString() }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-center">
            <v-chip
              :color="ticket?.status === 'close' ? 'grey' : 'success'"
              small
              class="ml-2"
            >
              {{ ticket?.status === 'close' ? 'סגור' : 'פתוח' }}
            </v-chip>
            <v-chip
              :color="ticket?.status === 'close' ? 'grey darken-2' : 'primary'"
              dark
              class="ticket-number"
            >
              #{{ ticket?.integer_id }}
            </v-chip>
          </div>
        </div>
      </v-container>
    </v-app-bar>

    <ProgressShow v-if="progressShow" v-model="progressShow"></ProgressShow>

    <v-container v-if="ticket" class="pt-8" style="max-width: 1300px;">
      <!-- Ticket Details Card -->
      <v-card class="mb-8 ticket-details" elevation="2">
        <v-card-title class="ticket-title">
          <div class="d-flex flex-column w-100">
            <span class="overline mb-2">נושא הפנייה</span>
            <h2 class="text-h5 font-weight-medium">{{ ticket.subject }}</h2>
          </div>
        </v-card-title>
      </v-card>

      <!-- Messages Timeline -->
      <div class="messages-timeline">
        <div class="timeline-header mb-6">
          <h3 class="text-h6 font-weight-medium">
            <v-icon left>mdi-message-text-outline</v-icon>
            היסטוריית שיחה
          </h3>
        </div>
        
          <div v-for="(message, index) in allMessages" :key="message._id" class="message-container" :class="{ 'message-right': message.responder_id.role === role }">
            <v-card
              :class="['message-card', { 'admin-message': message.responder_id.role === role }]"
              elevation="2"
            >
              <v-card-title class="message-header py-2 px-4">
                <div class="d-flex justify-space-between align-center w-100">
                  <div class="d-flex align-center">
                    <v-avatar size="32" color="primary" class="ml-2">
                      <span class="white--text">{{ message.responder_id.name.charAt(0) }}</span>
                    </v-avatar>
                    <div>
                      <div class="subtitle-2 font-weight-medium">{{ message.responder_id.name }}</div>
                      <div class="caption grey--text">{{ message.responder_id.role === 'admin' ? 'צוות תמיכה' : 'משתמש' }}</div>
                    </div>
                  </div>
                  <span class="caption grey--text">{{ new Date(message.createdAt).toLocaleString() }}</span>
                </div>
              </v-card-title>
              <v-card-text class="message-content py-4 px-4" v-html="formatMessage(message.message)"></v-card-text>
            </v-card>
          </div>
      </div>

      <!-- Reply Section -->
      <v-card v-if="ticket.status === 'open'" class="reply-section mt-6" elevation="3">
        <v-card-title class="reply-header">
          <v-icon left color="primary">mdi-reply</v-icon>
          הוסף תגובה
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="reply"
            outlined
            placeholder="כתוב את תגובתך כאן..."
            auto-grow
            rows="4"
            hide-details
            class="mb-4"
            background-color="grey lighten-5"
          ></v-textarea>
          <div class="d-flex justify-space-between align-center">
            <v-btn
              color="primary"
              :disabled="!reply.trim()"
              @click="handleReplySubmit"
              :loading="progressShow"
              class="px-6"
              elevation="2"
            >
              <v-icon left>mdi-send</v-icon>
              שלח תגובה
            </v-btn>
            <v-btn
              v-if="isOwner"
              color="error"
              outlined
              @click="openAlertDialog(ticket)"
              :disabled="progressShow"
              class="px-6"
            >
              <v-icon left>mdi-close-circle</v-icon>
              סגור פנייה
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <!-- Close Ticket Dialog -->
      <v-dialog v-model="alertDialog" max-width="400">
        <v-card>
          <v-card-title class="headline primary white--text">
            <v-icon left color="white">mdi-alert-circle</v-icon>
            סגירת פנייה
          </v-card-title>
          <v-card-text class="pt-4">
            <p class="subtitle-1">האם אתה בטוח שברצונך לסגור את הפנייה?</p>
            <p class="caption error--text mt-2">
              <v-icon small color="error" class="mr-1">mdi-information</v-icon>
              לא ניתן יהיה להוסיף תגובות נוספות לאחר הסגירה
            </p>
          </v-card-text>
          <v-card-actions class="pb-4 px-4">
            <v-spacer></v-spacer>
            <v-btn text @click="alertDialog = false" class="mr-2">ביטול</v-btn>
            <v-btn
              color="error"
              @click="handleCloseTicket(ticketToClose)"
              :loading="progressDialog"
            >
              סגור פנייה
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <SnackBar
      v-model="snackbar"
      :snacbarText="snacbarText"
      :snackbarColorBt="snackbarColorBt"
    ></SnackBar>
  </div>
</template>

<script>
import Auth from '@/services/auth.service.js'
import ProgressShow from '@/components/widgets/progressShow.vue'
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import { mapState } from 'vuex'

export default {
  name: 'TicketComp',
  components: {
    ProgressShow,
    SnackBar
  },
  data() {
    return {
      progressShow: false,
      progressDialog: false,
      snackbar: false,
      snackbarColorBt: "success",
      snacbarText: "",
      id: this.$route.params.id,
      reply: '',
      allMessages: [],
      ticket: null,
      alertDialog: false,
      ticketToClose: null,
      role: Auth.getUserRole(),
    }
  },
  computed: {
    isOwner() {
      return this.ticket?.email === Auth.getUserEmail()
    },
    ...mapState({
      newResponse: state => state.newResponse,
      ticketId: state => state.ticketId,
    }),
  },
  methods: {
    async getTicketMessagesList() {
      try {
        this.progressShow = true
        let token = localStorage.getItem("token")
        const params = { id: this.id }
        let api = `${process.env.VUE_APP_BASE_URL}/tickets/get_ticket_messages?ticketId=${this.id}`
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token))
        const jsonObject = await res.json()
        
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "error")
        } else if (res.status === 200) {
          this.ticket = jsonObject
          this.allMessages = JSON.parse(JSON.stringify(jsonObject.responses))
          this.handleMarkAsRead(jsonObject, params)
        }
      } catch (error) {
        this.showSnackBar("שגיאה בקבלת נתונים: " + error, "error")
      } finally {
        this.progressShow = false
      }
    },
    async handleMarkAsRead(ticketArg, params) {
      try {
        const responses = ticketArg.responses
        const lastResponse = responses[responses.length - 1]
        if (!lastResponse.read_at) {
          let token = localStorage.getItem("token")
          let api = `${process.env.VUE_APP_BASE_URL}/tickets/mark_as_read?ticketId=${params.id}`
          await fetch(api, ApiServices.requestOptions("GET", "", token))
        }
      } catch (error) {
        console.error("Error marking as read:", error)
      }
    },
    formatMessage(message) {
      return message.split('\n').map(line => `<p class="mb-1">${line}</p>`).join('')
    },
    async handleReplySubmit() {
      if (!this.reply.trim()) return
      
      try {
        this.progressShow = true
        let token = localStorage.getItem("token")
        let api = `${process.env.VUE_APP_BASE_URL}/tickets/reply_message`
        const myJson = JSON.stringify({
          ticketId: this.id,
          newMessage: this.reply
        })
        const res = await fetch(api, ApiServices.requestOptions("POST", myJson, token))
        const jsonObject = await res.json()
        
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בשליחת התגובה", "error")
        } else if (res.status === 200) {
          this.allMessages.push(jsonObject)
          this.reply = ''
          this.showSnackBar('התגובה נשלחה בהצלחה', 'success')
        }
      } catch (error) {
        this.showSnackBar(`שגיאה בשליחת התגובה: ${error}`, 'error')
      } finally {
        this.progressShow = false
      }
    },
    openAlertDialog(ticket) {
      this.alertDialog = true
      this.ticketToClose = ticket
    },
    async handleCloseTicket(ticket) {
      try {
        this.progressDialog = true
        let token = localStorage.getItem("token")
        let api = `${process.env.VUE_APP_BASE_URL}/tickets/close_ticket?ticketId=${ticket._id}`
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token))
        
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בסגירת הפנייה", "error")
        } else if (res.status === 200) {
          this.ticket.status = 'close'
          this.alertDialog = false
          this.showSnackBar('הפנייה נסגרה בהצלחה', 'success')
        }
      } catch (error) {
        this.showSnackBar(`שגיאה בסגירת הפנייה: ${error}`, 'error')
      } finally {
        this.progressDialog = false
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message
      this.snackbarColorBt = color
      this.snackbar = true
    },
  },
  watch: {
    newResponse(newValue) {
      if (this.ticket._id === this.ticketId) {
        this.allMessages.push(newValue)
        this.ticket.responses.push(newValue)
      }
    }
  },
  mounted() {
    this.getTicketMessagesList()
  }
}
</script>

<style scoped>
.ticket-detail-container {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ticket-number {
  font-weight: 600;
  letter-spacing: 0.5px;
  min-width: 80px;
  justify-content: center;
}

.ticket-details {
  border-radius: 12px;
  overflow: hidden;
}

.ticket-title {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.messages-timeline {
  padding: 20px 0;
}

.timeline-header {
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 16px;
}

.message-container {
  margin-bottom: 24px;
  max-width: 75%;
  transition: all 0.3s ease;
}

.message-right {
  margin-right: auto;
}

.message-card {
  width: fit-content;
  max-width: 80%; /* prevent card from being too wide */
  word-break: break-word; /* to ensure long words wrap */
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.message-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.message-card.admin-message {
  background-color: #e3f2fd; /* Light blue, modern and elegant */
  border-right: 4px solid #1976d2;
}

.message-content {
  color: #2c3e50;
  font-size: 0.95rem;
  line-height: 1.6;
}

.message-content p {
  margin-bottom: 0.5rem;
}

.reply-section {
  position: sticky;
  bottom: 20px;
  margin: 0 auto;
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
}

.reply-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  padding: 16px 24px;
}

/* RTL Specific Styles */
.v-application--is-rtl .message-right {
  margin-left: 0;
  margin-right: auto;
}

.v-application--is-rtl .message-container:not(.message-right) {
  margin-right: 0;
  margin-left: auto;
}

.v-application--is-rtl .admin-message {
  border-right: none;
  border-left: 4px solid #1976d2;
}
</style>