<template>
  <div class="reports-container" dir="rtl">
    <div class="header-bar">
      <span class="header-title">דוחות מנויים</span>
    </div>
    
    <div class="content-wrapper">
      <v-card elevation="3" class="rounded-lg mb-6 reports-card">
        <v-card-title class="d-flex align-center py-4 px-6 primary-bg">
          <v-icon large color="white" class="mr-3">mdi-file-document-outline</v-icon>
          <span class="text-h4 font-weight-bold white--text">סינון דוחות</span>
        </v-card-title>
        
        <v-card-text class="pa-6">
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="filterType"
                :items="statusOptions"
                label="סטטוס מנויים"
                outlined
                hide-details
                prepend-inner-icon="mdi-filter-variant"
                class="rounded-lg filter-select text_fields"
                color="#0d2c6d"
              ></v-select>
            </v-col>
            
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="מתאריך"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        outlined
                        hide-details
                        class="rounded-lg text_fields"
                        color="#0d2c6d"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="startDateMenu = false"
                      color="#0d2c6d"
                      header-color="#0d2c6d"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="עד תאריך"
                        prepend-inner-icon="mdi-calendar-end"
                        readonly
                        outlined
                        hide-details
                        class="rounded-lg text_fields"
                        color="#0d2c6d"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @input="endDateMenu = false"
                      color="#0d2c6d"
                      header-color="#0d2c6d"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
          <v-divider class="my-6"></v-divider>
          
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6" md="4" class="text-center">
              <v-btn
                color="#0d2c6d"
                x-large
                rounded
                elevation="2"
                :loading="progressShow"
                @click="exportFiltersManuim"
                :disabled="!filterType"
                height="56"
                class="export-btn px-6"
                dark
              >
                <v-icon left size="24">mdi-microsoft-excel</v-icon>
                <span class="text-h6">ייצוא לאקסל</span>
              </v-btn>
            </v-col>
            
            <v-col cols="12" sm="6" md="4" class="text-center">
              <v-btn
                text
                color="grey darken-1"
                @click="resetFilters"
                :disabled="progressShow"
                class="reset-btn"
              >
                <v-icon left>mdi-refresh</v-icon>
                איפוס סינון
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      
      <v-card elevation="2" class="rounded-lg info-card">
        <v-card-title class="d-flex align-center py-3 px-6 secondary-bg">
          <v-icon color="#0d2c6d" class="mr-2">mdi-information-outline</v-icon>
          <span class="text-subtitle-1 font-weight-bold primary-text">הנחיות שימוש</span>
        </v-card-title>
        
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12" md="4">
              <div class="d-flex align-center mb-3">
                <v-avatar color="#dae1eb" size="36" class="mr-3">
                  <v-icon color="#0d2c6d">mdi-filter</v-icon>
                </v-avatar>
                <div>
                  <div class="text-subtitle-1 font-weight-medium primary-text">בחירת סטטוס</div>
                  <div class="text-caption text-grey-darken-1">בחר את סוג המנויים שברצונך לייצא</div>
                </div>
              </div>
            </v-col>
            
            <v-col cols="12" md="4">
              <div class="d-flex align-center mb-3">
                <v-avatar color="#dae1eb" size="36" class="mr-3">
                  <v-icon color="#0d2c6d">mdi-calendar-range</v-icon>
                </v-avatar>
                <div>
                  <div class="text-subtitle-1 font-weight-medium primary-text">סינון לפי תאריכים</div>
                  <div class="text-caption text-grey-darken-1">הגדר טווח תאריכים לסינון מדויק יותר</div>
                </div>
              </div>
            </v-col>
            
            <v-col cols="12" md="4">
              <div class="d-flex align-center mb-3">
                <v-avatar color="#dae1eb" size="36" class="mr-3">
                  <v-icon color="#0d2c6d">mdi-download</v-icon>
                </v-avatar>
                <div>
                  <div class="text-subtitle-1 font-weight-medium primary-text">הורדת הקובץ</div>
                  <div class="text-caption text-grey-darken-1">לחץ על כפתור הייצוא להורדת קובץ אקסל</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    
    <v-overlay :value="progressShow" opacity="0.8">
      <v-card class="pa-4 rounded-lg text-center" max-width="300" elevation="8">
        <v-progress-linear 
          indeterminate 
          color="#0d2c6d" 
          height="6"
          class="mb-4"
        ></v-progress-linear>
        <div class="text-h6 font-weight-medium white-text">מייצא נתונים...</div>
        <div class="text-body-2 text-grey">אנא המתן בזמן שאנו מכינים את הקובץ שלך</div>
      </v-card>
    </v-overlay>
    
    <SnackBar 
      v-model="snackbar" 
      :snacbarText="snacbarText" 
      :snackbarColorBt="snackbarColorBt"
    ></SnackBar>
  </div>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils"

export default {
  components: {
    SnackBar,
  },

  data: () => ({
    overlay: false,
    snackbar: false,
    snackbarColorBt: "success",
    snacbarText: "",
    progressShow: false,
    filterType: "active",
    statusOptions: [
      { text: "מנויים פעילים", value: "active" },
      { text: "מנויים לא פעילים", value: "not_active" },
      { text: "מנויים בתהליך", value: "proccess" },
      { text: "כל המנויים", value: "all" }
    ],
    startDate: null,
    endDate: null,
    startDateMenu: false,
    endDateMenu: false
  }),

  computed: {
    dateRangeParams() {
      let params = '';
      if (this.startDate) {
        params += `&startDate=${this.startDate}`;
      }
      if (this.endDate) {
        params += `&endDate=${this.endDate}`;
      }
      return params;
    }
  },

  methods: {
    async exportFiltersManuim() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/reports/export_filter_manuim_to_excel_v2?filterType=${this.filterType}${this.dateRangeParams}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        this.progressShow = false;
        
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "error");
        } else if (res.status === 200) {
          // Convert response to Blob for download
          const blob = await res.blob();
          // Create a link element for downloading the Blob
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          
          // Set the desired file name based on filter type
          let fileName = "";
          switch(this.filterType) {
            case "active":
              fileName = "מנויים_פעילים";
              break;
            case "not_active":
              fileName = "מנויים_לא_פעילים";
              break;
            case "proccess":
              fileName = "מנויים_בתהליך";
              break;
            default:
              fileName = "כל_המנויים";
          }
          
          link.download = `${fileName}.xlsx`;
          document.body.appendChild(link);
          link.click(); // Programmatically click the link to trigger download
          link.remove(); // Remove the link after download
          
          // Revoke the object URL after download
          window.URL.revokeObjectURL(downloadUrl);
          this.showSnackBar("הקובץ הורד בהצלחה", "success");
        }
      } catch (error) {
        this.progressShow = false;
        console.error(error);
        this.showSnackBar("שגיאה בהורדת הקובץ: " + error, "error");
      }
    },
    
    showSnackBar(text, color) {
      this.snacbarText = text;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    
    resetFilters() {
      this.filterType = "active";
      this.startDate = null;
      this.endDate = null;
    }
  },

  watch: {
    startDate(val) {
      // Ensure end date is not before start date
      if (this.endDate && val && new Date(val) > new Date(this.endDate)) {
        this.endDate = val;
      }
    }
  },
  
  created() {
    // Initialize with default values if needed
    this.filterType = "active";
  }
}
</script>

<style scoped>
.reports-container {
  width: 100%;
  margin: 0 auto;
}

.header-bar {
  height: 50px;
  background-color: #dae1eb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.header-title {
  font-size: large;
  color: #0d2c6d;
  font-weight: 600;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 0 auto 100px auto;
}

.reports-card {
  border-radius: 20px !important;
  overflow: hidden;
  background-color: white;
  margin-bottom: 20px;
}

.info-card {
  border-radius: 20px !important;
  overflow: hidden;
  background-color: white;
}

.primary-bg {
  background-color: #0d2c6d !important;
}

.secondary-bg {
  background-color: #f3f6f9 !important;
}

.primary-text {
  color: #0d2c6d !important;
}

.text_fields {
  border: 1px solid #eee;
  border-radius: 13px;
  padding-left: 10px;
  padding-right: 10px;
}

.filter-select {
  border-radius: 13px;
}

.export-btn {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: none;
  background-color: #0d2c6d !important;
}

.reset-btn {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: none;
}

/* RTL specific adjustments */
.v-application--is-rtl .v-text-field--outlined fieldset {
  padding-right: 8px;
}
</style>