<template >
  <div>
    <div style="
        height: 50px;
        background-color: #dae1eb;
        display: flex;
        justify-content: center;
        align-items: center;
      ">
      <span style="font-size: large; color: #0d2c6d">הקמת מנוי</span>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 100px;
        margin-top: 50px;
      ">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10" lg="7" style="background-color: white; border-radius: 5px; padding: 20px">
          <v-card class="mx-auto" outlined>
            <div v-if="!isDone">
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1" color="#0d2c6d">פרטים אישיים</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 2" step="2" color="#0d2c6d">חבילה</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 3" step="3" color="#0d2c6d">פרטי תשלום</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4" color="#0d2c6d">פרטי סים</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form ref="form1">
                      <v-row class="my-5 mx-5 d-none d-md-flex">
                        <v-col cols="12" md="4" style="display: flex;">
                          <!-- <v-autocomplete label="בחר לקוח מהרשימה" hide-details dense :items="existCustomers" outlined
                          item-text="card_id" item-value="fullObject" return-object v-model="selectedCustomer"
                          required></v-autocomplete> -->
                          <v-text-field v-if="!customerExist" label="תעודת זהות" hide-details dense type="number"
                            @keydown.enter.prevent="handleEnterKeyOtp" hide-spin-buttons outlined
                            v-model="customerDetails.card_id" :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>

                          <v-btn v-if="!customerExist" dark color="#8066f0" style="margin-right: 15px;"
                            @click="checkId">אישור</v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="showFields" class="my-5 mx-5 d-none d-md-flex">
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="שם פרטי" flat outlined dense  v-model="customerDetails.name"
                            :disabled="customerExist" :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="משפחה" outlined dense v-model="customerDetails.family"
                            :disabled="customerExist" :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="שם לחשבונית" outlined dense required :disabled="customerExist"
                            v-model="customerDetails.business_name"></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                        <v-text-field label="ת.ז" type="number" hide-spin-buttons outlined dense
                          v-model="customerDetails.card_id" required></v-text-field>
                      </v-col> -->
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="טלפון" type="number" hide-spin-buttons outlined dense
                            :disabled="customerExist" v-model="customerDetails.phone1" required
                            :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="טלפון בית" type="number" hide-spin-buttons outlined
                            :disabled="customerExist" v-model="customerDetails.phone2" dense required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="אימייל" type="email" outlined dense v-model="customerDetails.email"
                            :disabled="customerExist"  :rules="emailRules" ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="רחוב" outlined dense v-model="customerDetails.address"
                             :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="מספר בית" hide-spin-buttons outlined
                             v-model="customerDetails.building" dense :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="עיר" outlined dense v-model="customerDetails.city"
                             :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="מיקוד" type="number" hide-spin-buttons outlined dense
                            :disabled="customerExist" v-model="customerDetails.postal_code" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-select :items="communities" label="קהילה" :item-text="'name'" :item-value="'_id'" outlined
                            dense :disabled="customerExist" v-model="customerDetails.community_id" required
                            :rules="[(v) => !!v || 'שדה חובה']"></v-select>
                        </v-col>
                      </v-row>
                      <div style="direction: ltr">
                        <v-btn color="#8066f0" dark @click="validateAndNext(2, 'form1')">{{ "< " }} שלב הבא</v-btn
                                                                                                  >
                                                                                                </div>
                                                                                              </v-form>
                                                                                            </v-stepper-content>


                                                                  <v-stepper-content step="2">
                            <v-form ref="form2">
                              <div class="padding-responsive">
                                <div class="padding-responsive">
                                  <span style="font-size: 24px; color: #6A707E;">בחר חבילה</span>
                                  <div style="margin-top: 10px; max-width: 300px;">
                                    <v-select :items="operatingCompaniesOptions" label="בחר חברה מפעילה" :item-text="'hebrewName'" :item-value="'name'" outlined
                                    dense v-model="operationCompanySelected" required
                                    :rules="[(v) => !!v || 'שדה חובה']"></v-select>
                                  </div>
                                  <v-row class="margin-responsive">
                                    <v-col cols="auto" md="6" v-for="(item, index) in filteredPlans">

                                      <div class="box_credit_buy_package"
                                        style="width: 600px; max-width: 100%; background-color: white; margin-bottom: 15px;">
                                        <v-row>
                                          <v-col colls="12" sm="8">
                                            <div style="padding: 15px; display: flex; justify-content: center;">
                                              <span style="font-size: large; color: #0d2c6d;">{{ item.name
                                              }}</span>
                                            </div>

                                            <div style="padding: 15px; display: flex; justify-content: center;">
                                              <span style="color: #888484;">{{ item.details
                                              }}</span>
                                            </div>

                                            <div style="margin: 10px;">
                                              <v-btn color="#0d2c6d" :outlined="clickedButtonIndex !== index"
                                                :dark="clickedButtonIndex === index" style="width: 150px;"
                                                @click="buttonClicked(index, item._id)">
                                                לרכישה
                                              </v-btn>
                                            </div>
                                          </v-col>
                                          <v-col colls=" 12" sm="4">
                                            <div
                                              style="height: 90% !important;  border: 1px solid #8066f0; margin: 10px; ">
                                              <div style="display: flex; justify-content: center;">
                                                <v-avatar style="width: 80%; height: 70%; margin: 10px;">
                                                  <img :src="getCompanyLogo(item.operating_company)" contain alt="image">
                                                </v-avatar>
                                              </div>
                                              <div style="display: flex; justify-content: center; margin: 10px;">
                                                <span style="color: #8066f0; font-weight: 600;">{{ item.price }}
                                                  ש"ח</span>
                                              </div>
                                              <div v-if="hasSetupFee > 0" style="display: flex; justify-content: center;">
                                                <div><span style="font-size: small; color: #8066f0;">דמי הקמת מנוי:
                                                  </span>
                                                </div>
                                              </div>
                                              <div v-if="hasSetupFee > 0" style="display: flex; justify-content: center;">
                                                <div><span style="font-weight: bold; color: #8066f0;"> {{ hasSetupFee + ""
                                                }} ₪</span></div>
                                              </div>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </v-col>
                                  </v-row>

                                </div>
                              </div>
                              <div style="direction: ltr">
                                <v-btn color="#8066f0" dark @click="validateAndNext(3, 'form2')"
                                  style="margin-right: 15px">{{ "< " }} שלב הבא</v-btn>
                                                                      <v-btn color=" #eee" @click="e1 = 1">שלב קודם{{ " >"
                                                                      }}</v-btn>
                              </div>
                            </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-form ref="form3">
                      <v-row v-if="!customerExist">
                        <v-col cols="12" sm="3"> </v-col>
                        <v-col cols="12" sm="6">
                          <v-select label="בחר סוג תשלום" dense :items="selectPayType" outlined :item-text="'hebrewName'"
                            :item-value="'name'" v-model="payDetails.payType" style="margin-top: 10px;" required
                            :rules="[(v) => !!v || 'שדה חובה']"></v-select>
                        </v-col>
                        <v-col cols="12" sm="3"> </v-col>
                      </v-row>
                      <div v-if="payDetails.payType === 'creditCard'">
                        <v-row v-if="!customerDetails.last_digits">
                          <v-col cols="12" sm="3"> </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field label="מספר כרטיס" type="number" hide-spin-buttons outlined dense
                              style="margin-top: 10px;" v-model="payDetails.card_number" required
                              :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                            <v-text-field label="תוקף" type="number" hide-spin-buttons outlined v-model="payDetails.tokef"
                              dense required :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                            <v-text-field label="ספרות בטיחות" type="number" hide-spin-buttons outlined
                              v-model="payDetails.cvv" dense required :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3"> </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="12" sm="3"> </v-col>
                          <v-col cols="12" sm="6">
                            <v-checkbox :label="'אשראי מספר: ' + customerDetails.last_digits" v-model="isChecked" disabled
                              selected hide-details></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="3"> </v-col>
                        </v-row>
                        <v-row v-if="operateByAdmin" style="margin-bottom: 10px;">
                          <v-col cols="12" sm="3"> </v-col>
                          <v-col cols="12" sm="6">
                            <v-checkbox :label="'דלג על J5'" v-model="adminSkipJ5" hide-details></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="3"> </v-col>
                        </v-row>
                      </div>
                      <div v-else-if="payDetails.payType === 'bankHok'">
                        <v-row v-if="customerDetails.bankHok">
                          <v-col cols="12" sm="3"> </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field label="מספר בנק" disabled outlined dense item-text="name" item-value="code"
                              style="margin-top: 10px;" v-model="customerDetails.bankHok.bank_code" required
                              :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                            <v-text-field label="מספר סניף" disabled outlined dense item-text="name" item-value="code"
                              v-model="customerDetails.bankHok.branch_code" required
                              :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                            <v-text-field label="מספר חשבון" disabled type="number" hide-spin-buttons outlined
                              v-model="customerDetails.bankHok.account_code" dense required
                              :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3"> </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="12" sm="3"> </v-col>
                          <v-col cols="12" sm="6">
                            <div style="display: flex;">
                              <v-icon v-bind="attrs" v-on="on" small style="margin-right: 10px;">
                                mdi-exclamation-thick
                              </v-icon>
                              <span style="color: rgba(180, 13, 13, 0.801); font-weight: bold;">שימו לב! המנוי יוקם לאחר אישור הקמת הרשאת חיוב מהבנק</span>
                            </div>
                            <v-autocomplete label="מספר בנק" outlined dense :items="formattedBankCodes" item-text="name"
                              item-value="code" style="margin-top: 10px;" v-model="payDetails.bankCode"
                              @change="getBankBranches()" required :rules="[(v) => !!v || 'שדה חובה']"></v-autocomplete>
                            <v-autocomplete label="מספר סניף" outlined dense :items="formattedBankBranches"
                              item-text="name" item-value="code" v-model="payDetails.branchCode" required
                              :rules="[(v) => !!v || 'שדה חובה']"></v-autocomplete>
                            <v-text-field label="מספר חשבון" type="number" hide-spin-buttons outlined
                              v-model="payDetails.accountCode" dense required
                              :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3"> </v-col>
                        </v-row>
                      </div>

                      <div style="direction: ltr">
                        <v-btn color="#8066f0" dark @click="validateAndNext(4, 'form3')" style="margin-right: 15px">{{ "<"
                        }} שלב הבא</v-btn>
                            <v-btn color="#eee" @click="e1 = 2">שלב קודם{{ " >" }}</v-btn>
                      </div>
                    </v-form>
                  </v-stepper-content>


                  <v-stepper-content step="4">
                    <v-form ref="form4">
                      <v-row>
                        <v-col cols="12" sm="3"> </v-col>
                        <v-col cols="12" sm="6">
                          <v-select label="בחר סוג מנוי" dense :items="selectType" outlined v-model="simDetails.manuiType"
                            style="margin-top: 10px;" required :rules="[(v) => !!v || 'שדה חובה']"></v-select>

                          <v-text-field v-if="simDetails.manuiType === 'מנוי חדש' || simDetails.manuiType === 'ניוד'"
                            label="מספר סים" type="number" hide-spin-buttons outlined dense v-model="simDetails.simNumber"
                            required :rules="[(v) => !!v || 'שדה חובה', (v) => validateSimStart(v) ||
                             `המספר סים שהזנתם אינו תואם למפעיל ${selectedPlan.operating_company} שבחרתם`, validateSimLength]"></v-text-field>

                          <v-text-field v-if="simDetails.manuiType === 'ניוד'" label="מספר לניוד" type="number"
                            hide-spin-buttons outlined dense v-model="simDetails.manuiNumber" required
                            :rules="[(v) => !!v || 'שדה חובה', (v) => (v.length === 10) || 'מספר מנוי חייב להכיל בדיוק 10 תווים', validateManuiStart]"></v-text-field>

                          <v-text-field v-if="simDetails.manuiType === 'העברת בעלות'" label="מספר מנוי" type="number"
                            hide-spin-buttons outlined dense v-model="simDetails.manuiNumber" required
                            :rules="[(v) => !!v || 'שדה חובה', (v) => (v.length === 10) || 'מספר מנוי חייב להכיל בדיוק 10 תווים', validateManuiStart]"></v-text-field>

                          <v-select v-if="simDetails.manuiType && simDetails.manuiType === 'ניוד'"
                            :items="constants.companies" v-model="simDetails.abandoned_company" outlined dense
                            label="חברה ננטשת" required :rules="[(v) => !!v || 'שדה חובה']">
                          </v-select>

                          <v-select v-if="simDetails.manuiType" :items="levels" :item-text="getItemText"
                            :item-value="'level_code'" v-model="simDetails.community_level" outlined dense
                            label="רמת סינון" required :rules="[(v) => !!v || 'שדה חובה']">
                          </v-select>

                          <v-checkbox v-if="simDetails.manuiType === 'ניוד'" label="בוצע אימות ניוד"
                            v-model="simDetails.improve_mobility" required
                            :rules="[(v) => !!v || 'שדה חובה']"></v-checkbox>

                          <v-file-input
                            v-if="simDetails.manuiType === 'העברת בעלות' && selectedPlan.operating_company === 'hotMobile'"
                            label="צרף צילום תעודת זהות" outlined dense style="width: 300px; max-width: 100%;"
                            v-model="idImage" prepend-icon="mdi-camera" placeholder="לא נבחרו קבצים" show-size
                            color="deep-purple accent-4" required :rules="[(v) => !!v || 'שדה חובה']"></v-file-input>

                             <v-select v-if="simDetails.manuiType" :items="jingleOptions" :item-text="'name'"
                            :item-value="'code'" v-model="simDetails.jingle" outlined dense
                            label="ג'ינגל">
                          </v-select>

                          <div v-if="simDetails.manuiType === 'העברת בעלות' && selectedPlan.operating_company === 'hotMobile'">
                            <v-checkbox style="margin-bottom: 10px;" :label="'הזן פרטי לקוח שונים'" v-model="costumeForwardDetails.isChecked" hide-details></v-checkbox>
                            <div v-if="costumeForwardDetails.isChecked">
                              <v-text-field label="שם" outlined dense v-model="costumeForwardDetails.forwardName"
                            :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                            <v-text-field label="משפחה" outlined dense v-model="costumeForwardDetails.forwardFamily"
                            :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                            <v-text-field label="ת.ז" outlined dense v-model="costumeForwardDetails.forwardCardId" type="number" hide-spin-buttons
                            :rules="[(v) => !!v || 'שדה חובה']" required></v-text-field>
                            </div>
                            </div>
                        
                        </v-col>
                        <v-col cols="12" sm="3"> </v-col>
                      </v-row>
                      <div style="direction: ltr">
                        <v-btn color="#8066f0" dark @click="saveNewManui" style="margin-right: 15px">סיום</v-btn>
                        <v-btn color="#eee" @click="e1 = 3">שלב קודם{{ " >" }}</v-btn>
                      </div>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
            <div v-else style="text-align: center;">
              <span style="font-size: xx-large; color: rgba(14, 131, 14, 0.685);">המנוי נוצר בהצלחה!</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <template>
      <v-overlay :value="progressShow">
        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
      </v-overlay>
    </template>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>
    
<script>
import SnackBar from "@/components/widgets/snackBar";
import ApiServices from "@/services/api.service";
import Utils from "@/util/utils";
import Auth from "@/services/auth.service";
import SharedFunctions from "@/shared/SharedFunctions";
import axios from "axios";

export default {
  components: {
    SnackBar,
  },
  data: () => ({
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    search: "",
    customerDetails: {
      name: "",
      family: "",
      business_name: "",
      card_id: "",
      phone1: "",
      phone2: "",
      email: "",
      address: "",
      building: "",
      city: "",
      postal_code: "",
      last_digits: "",
      bankHok: "",
      community_id: "",
    },
    costumeForwardDetails: {
      isChecked: false,
      forwardName: "",
      forwardFamily: "",
      forwardCardId: "",
    },
    payDetails: {
      payType: "",
      bankCode: "",
      branchCode: "",
      accountCode: "",
      card_number: "",
      tokef: "",
      cvv: "",
    },
    simDetails: {
      manuiType: "",
      abandoned_company: "",
      improve_mobility: false,
      simNumber: "",
      manuiNumber: "",
      community_level: "",
      jingle: 0,
    },
    operationCompanySelected: "",
    idImage: null,
    plans: [],
    constants: [],
    progressShow: false,
    e1: 1, // This keeps track of the active step
    customerExist: false,
    showFields: false,
    selectedPlan: "",
    existCustomers: [], // Items for v-select
    clickedButtonIndex: null,
    selectPayType: [{ hebrewName: "כרטיס אשראי", name: "creditCard" }, { hebrewName: 'הו"ק בנקאי', name: "bankHok" }],
    hasSetupFee: 0,
    isChecked: true, // Controls the checkbox state
    communities: [],
    isDone: false,
    adminSkipJ5: false,
    operateByAdmin: false,
    operatingCompaniesOptions: [{name: "hotMobile", hebrewName: "הוט מובייל"}, {name: "partner", hebrewName: "פרטנר"}, {name: "clickCallMobile", hebrewName: "קליקול מובייל"}],
    bankCodes: [
      { code: '10', name: 'בנק לאומי' },
      { code: '12', name: 'בנק הפועלים' },
      { code: '20', name: 'בנק מזרחי טפחות' },
      { code: '11', name: 'בנק דיסקונט' },
      { code: '17', name: 'בנק מרכנתיל דיסקונט' },
      { code: '31', name: 'הבנק הבינלאומי' },
      { code: '13', name: 'בנק איגוד' },
      { code: '14', name: 'בנק אוצר החייל' },
      { code: '52', name: 'בנק פועלי אגודת ישראל' },
      { code: '4', name: 'בנק יהב' },
      { code: '54', name: 'בנק ירושלים' },
      { code: '18', name: 'וואן זירו' },
      { code: '68', name: 'מוניציפל בנק' },
      { code: '46', name: 'בנק מסד' },
      { code: '26', name: 'יובנק' },
      { code: '9', name: 'בנק הדואר' },
      { code: '3', name: 'בנק אש ישראל' },
      { code: '99', name: 'בנק ישראל' }
    ],
    branches: [],
    // jingleOptions: [{code: 0, name: "לא פעיל"}, {code: 1, name: "ג'ינגל + צליל טרום חיוג"}, {code: 2, name: "ג'ינגל בלבד"}, {code: 3, name: "צליל טרום חיוג בלבד"}],
    jingleOptions: [{code: 0, name: "לא פעיל"},  {code: 3, name: "צליל טרום חיוג בלבד"}],
    emailRules: [
        (v) => !v || /.+@.+\..+/.test(v) || 'יש להזין כתובת אימייל תקינה',
      ],
  }),
  computed: {
    formattedBankCodes() {
      return this.bankCodes.map(bank => ({
        code: bank.code,
        name: `${bank.name} (${bank.code})`
      }));
    },
    formattedBankBranches() {
      return this.branches.map(branch => ({
        code: branch.Branch_Code,
        name: `${branch.Branch_Code} - ${branch.Branch_Address} - ${branch.City}`
      }));
    },
    filteredPlans() {
      return this.plans.filter(plan => plan.operating_company === this.operationCompanySelected);
    },
    selectType() {
      console.log(this.operationCompanySelected);
      
      return this.operationCompanySelected  === "clickCallMobile" ? ["מנוי חדש", "ניוד"] : ["מנוי חדש", "ניוד", "העברת בעלות"];
    }
  },
  methods: {
    validateAndNext(step, formNum) {
      // this.e1 = step; // Move to the next step

      if (this.$refs[formNum].validate()) {
        if (formNum === "form1") {
          const customerExist = this.existCustomers.some(customer => customer.card_id === this.customerDetails.card_id)
          if (customerExist && !this.customerExist) {
            return this.showSnackBar("הלקוח קיים, יש ללחוץ על אישור", "red");
          }
          if (!this.customerDetails.name) {
            return this.showSnackBar("יש ללחוץ על אישור", "red");
          }
          this.getLevels();
        }
        if (formNum === "form2") {
          if (!this.selectedPlan) {
            return this.showSnackBar("נא לבחור חבילה", "red");
          }
        }
        this.e1 = step; // Move to the next step
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    handleEnterKeyOtp(event) {
      if (event.keyCode === 13) {
        this.checkId(); // Trigger the button click event
      }
    },
    getItemText(item) {
      return `${item.level_name} (${item.level_code})`;
    },
    async getExistCustomers() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/customers/get_customers_limit_details`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.existCustomers = jsonObject.map(item => ({ card_id: item.card_id, fullObject: item }));
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get files: " + error, "red");
      }
    },
    async getDetails() {
      this.communities = await SharedFunctions.getCommunities();
      this.constants = await SharedFunctions.getConstants();
      this.plans = await SharedFunctions.getPlans();
      this.plans.sort((a, b) => a.operating_company.localeCompare(b.operating_company));
    },
    async getLevels() {
      this.levels = await SharedFunctions.getLevels(this.customerDetails.community_id);
    },
    async saveNewManui() {
      try {
        if (this.$refs.form4.validate()) {
          this.progressShow = true;

          if (this.simDetails.manuiType !== "העברת בעלות") {
            const simStatus = await this.checkSim();
          if (!simStatus) {
            this.progressShow = false;
            return  this.showSnackBar("שגיאה: מספר סים שהוזן כבר בשימוש", "red");
          }
          }          

          if (this.selectedPlan.operating_company === "clickCallMobile") {
            const simExist = await SharedFunctions.checkSimExistInSource(this.simDetails.simNumber, this.selectedPlan.operating_company);
            console.log(simExist);
            
          if (!simExist) { 
            this.progressShow = false;
            return  this.showSnackBar("שגיאה: מספר סים שהוזן לא קיים במאגר", "red");
          }
          }
          
          let token = localStorage.getItem("token");
          // this token for skip J5 cardcome
          let adminToken = localStorage.getItem("tokenAdmin") || null;
          // to handle insert number then change type to new
          if (this.simDetails.manuiType === "מנוי חדש") {
            this.simDetails.manuiNumber = ""
          }
          // Prepare FormData
          let formData = new FormData();
          formData.append("id_img", this.idImage); // Assuming `imageFile` is the image to be uploaded
          formData.append("adminToken", JSON.stringify(adminToken)); // Assuming `imageFile` is the image to be uploaded
          formData.append("skipJ5", JSON.stringify(this.adminSkipJ5)); // Assuming `imageFile` is the image to be uploaded
          formData.append("customerExist", JSON.stringify(this.customerExist)); // Append each detail from `otherDetails`
          formData.append("seller_id", Auth.getUserId()); // Append each detail from `otherDetails`
          formData.append("customer", JSON.stringify(this.customerDetails)); // Append each detail from `otherDetails`
          formData.append("cardDetail", JSON.stringify(this.payDetails)); // Append each detail from `otherDetails`
          formData.append("simDetails", JSON.stringify(this.simDetails)); // Append each detail from `otherDetails`
          formData.append("planDetails", JSON.stringify(this.selectedPlan)); // Append each detail from `otherDetails`
          formData.append("costumeForwardDetails", JSON.stringify(this.costumeForwardDetails)); // Append each detail from `otherDetails`

          // Adjust requestOptions for FormData and include the token
          let requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`, // Adjust as needed
            },
            body: formData,
          };

          let api = process.env.VUE_APP_BASE_URL + `/customers/create_customer_and_manui`;
          const res = await fetch(api, requestOptions);
          const jsonObject = await res.json();
          this.progressShow = false;
          if (res.status >= 400) {
            this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.isDone = true;
            setTimeout(() => {
              this.$router.replace({ name: "home" });
            }, 2000);
          }
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get files: " + error, "red");
      }
    },
    planChosed(planId) {
      this.selectedPlan = this.plans.find(plan => plan._id === planId);
    },
    buttonClicked(index, itemId) {
      // Update the clicked button's index
      this.clickedButtonIndex = index;
      // Now call your planChosen function or whatever you need to do when a button is clicked
      this.planChosed(itemId);
    },
    async checkId() {

      if (!this.customerDetails.card_id) {
        return;
      }

      let searchId;
      let existCust = this.existCustomers.find(customer => customer.card_id === this.customerDetails.card_id);
      if (!existCust) {
        // Check if card_id starts with "0"
        if (this.customerDetails.card_id.startsWith("0")) {
          // Remove the leading "0" for the search
          searchId = this.customerDetails.card_id.substring(1);
        } else {
          // Add a "0" at the beginning for the search
          searchId = "0" + this.customerDetails.card_id;
        }
        // Search for the customer with the modified card_id
        existCust = this.existCustomers.find(customer => customer.card_id === searchId);
      }

      const isValidCardId = this.is_israeli_id_number(this.customerDetails.card_id);
      const adminToken = localStorage.getItem("tokenAdmin") || null;
      if (!isValidCardId && !adminToken) {
        return this.showSnackBar("תעודת הזהות אינה תקינה", "red");
      }

      this.showFields = true
      if (existCust) {
        console.log(existCust);
        this.customerDetails = existCust.fullObject;
        this.customerExist = true;
        if (this.customerDetails.last_digits) {
          this.payDetails.payType = "creditCard";
        } else if (this.customerDetails.bankHok) {
          this.payDetails.payType = "bankHok";
        }
      } else {
        this.customerDetails = { card_id: this.customerDetails.card_id };
        this.customerExist = false;
      }
    },
    is_israeli_id_number(id) {
      id = String(id).trim();
      if (id.length > 9 || isNaN(id)) return false;
      id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
      return Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) % 10 === 0;
    },
    async getBankBranches() {
      this.branches = [];
      this.payDetails.branchCode = "";
      this.progressShow = true;
      if (this.payDetails.bankCode === "9") {
        this.branches = [{Branch_Code: "01", Branch_Address: "כללי", City: ""}];
        this.progressShow = false;
        return;
      }
      this.branches = await SharedFunctions.getBanksBranches(this.payDetails.bankCode);
      this.progressShow = false;
    },
    async checkSim() {
      const simStatus = await SharedFunctions.checkSim(this.simDetails.simNumber);
      return simStatus;
    },
    validateSimStart(value) {
      // Replace "123" with the specific digits you want to validate
      if (this.selectedPlan.operating_company === "hotMobile") {
        const validStartDigits = /^8997207/;
        return validStartDigits.test(value);
      }else if (this.selectedPlan.operating_company === "partner") {
        const validStartDigits = /^8997201/;
        return validStartDigits.test(value);
      }else if (this.selectedPlan.operating_company === "clickCallMobile") {
        return true;
      }
     
    },
    validateSimLength(v) {
      if (this.selectedPlan.operating_company === 'partner' && v.length !== 20) {
        return 'מספר סים חייב להכיל בדיוק 20 תווים עבור Partner';
      } else if (this.selectedPlan.operating_company === 'hotMobile' && v.length !== 19) {
        return 'מספר סים חייב להכיל בדיוק 19 תווים עבור Hot Mobile';
      }
      return true;
    },
    validateManuiStart(v) {
  const arrayKosherPrefix = ["05041", "05271", "05276", "05484", "05485", "05331", "05341", "05832", "05567", "05552", "055400"];
  // Check if the value doesn't start with any of the prefixes
  const isValidPrefix = arrayKosherPrefix.some(prefix => v.startsWith(prefix));
  if (!isValidPrefix) {
    return "המספר שהזנתם אינו תואם לקידומת הכשרה";
  }
  
  // If all checks pass, return true
  return true;
},
getCompanyLogo(company) {
            const extension = company === 'clickCallMobile' ? 'svg' : 'png';
            return require(`@/assets/${company}.${extension}`);
        }

  },
  created() {
    this.getExistCustomers();
    this.getDetails();
    this.hasSetupFee = Auth.getUserSetupFee();

    let adminToken = localStorage.getItem("tokenAdmin") || null;
    if (adminToken) {
      this.operateByAdmin = true;
    }
  },
};
</script>
<style ></style>
    