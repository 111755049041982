import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    newTicket: null,
    newResponse: null,
    ticketId: null,
    selectedNavItem: null,
    rerenderNav: false,
    colorPrimary: "#3F51B5",
    colorSecondary: "#5C6BC0",
    colorTertiary: "#E8EAF6"
  },
  mutations: {
    setRenderNav(state) {
      state.rerenderNav = !state.rerenderNav;
    },
    setNewTicket(state, ticket) {
      state.newTicket = ticket;
    },
    setReplyTicket(state, { reply, ticketId }) {
     
      state.newResponse = reply;
      state.ticketId = ticketId;
    },
  },
})