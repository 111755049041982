<template>
  <v-app style="background-color: #f3f6f9">
      <v-main>
         
          <ReportsComp />

      </v-main>
  </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'
import ReportsComp from '@/components/reports/ReportsComp.vue'

export default {
  components: {
      ReportsComp,
  },
  data() {
      return {
      };
  },
  methods: {
  },
  computed: {
  },
  mounted() {
      document.title = 'דוחות | סים לחומרא'
      let auth = Auth.checkAuth();
      if (!auth) {
          return this.$router.replace({ name: "login" })
      }
  },
}
</script>
<style>
</style>