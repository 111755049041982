<template>
    <div>

        <div
            style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">סימים</span>
        </div>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px; text-align: center;">
                <v-row>
                    <v-col cols="12" md="2" style="margin-top: 1%; display: flex; align-items: center;">
                        <v-btn color="black" dark @click="openCreateDIalog()">הוסף סימים חדשים</v-btn>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search" clearable
                        @input="handleSearchInput" class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ סימים
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ totalItems }}
                        </div>
                        <div style="margin-right: 10px;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="primary" @click="exportSims()">
                                        mdi-file-download-outline
                                    </v-icon>
                                </template>
                                <span>ייצוא לקובץ אקסל</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table id="table1" :headers="headers" :items="sims" hide-default-footer
                            :server-items-length="totalItems" :items-per-page="itemsPerPage" :page.sync="currentPage"
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @update:page="onPageChange" item-key="_id"
                            @update:items-per-page="onItemsPerPageChange" @update:sort-by="onSortChange"
                            @update:sort-desc="onSortChange">

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:item.company="{ item, index }">
                                    <td>{{ companies[item.company] }}</td>
                            </template>
                            <template v-slot:item.status="{ item, index }">
                                    <td>{{ item.status === "free" ? "פנוי" : "בשימוש" }}</td>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                </tr>
                            </template>
                            <template v-slot:item.createdAt="{ item, index }">
                                <tr>
                                    <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                                </tr>
                            </template>

                            <template v-slot:item.actions="{ item, index }" v-if="role === 'admin'">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openEditDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openDeleteDialog(item, index)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:no-data>
                                <v-row class="my-5">
                                    <v-col v-if="progressShow" class="d-flex justify-center">
                                        <v-progress-linear indeterminate color="#FDB721" height="6"
                                            style="width: 100%;"></v-progress-linear>
                                    </v-col>
                                </v-row>
                                <div class="ma-5">
                                    <span class="text-center"
                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא
                                        נמצאו
                                        נתונים</span>
                                </div>
                                <v-btn color="#0d2c6d" dark style="margin-bottom: 10px;" @click="getCommunities()">
                                    רענן
                                </v-btn>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" circle :length="Math.ceil(totalItems / itemsPerPage)"
                            :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            @simsCreated="newSimSaved" @simEdited="editedSimSaved"> </AddEditDialog>


        <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @itemDeleted="simDeleted" />
    </div>
</template>

<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import AddEditDialog from '@/components/sims/dialogs/AddEditDialog'
import AlertDialog from '@/components/sims/dialogs/AlertDialog'
import Utils from "@/util/utils";
import SharedFunctions from "@/shared/SharedFunctions";
import Auth from '@/services/auth.service';
import _ from 'lodash'; // Import lodash or use another debounce utility

export default {
    components: {
        SnackBar,
        AddEditDialog,
        AlertDialog
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index', align: 'center' },
            { text: 'מספר', value: 'number', align: 'center'  },
            { text: 'חברה', value: 'company', align: 'center'  },
            { text: 'סטטוס', value: 'status'  },
            { text: 'משוייך למשווק', value: 'seller_id.name' },
            { text: 'נוצר בתאריך', value: 'createdAt' },
            { text: 'פעולות', value: 'actions' },
        ],
        sims: [],
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        alertDialog: false,
        addEditDialog: false,
        itemToDelete: null,
        formTitle: "",
        progressShow: false,
        currentPage: 1,
        pageCount: 0,
        itemsPerPage: 20,
        totalItems: 0,
        sortBy: "date_created",
        companies: {
            "clickCallMobile": "קליקול מובייל",
            "hotMobile": "הוט מובייל",
            "partner": "פרטנר",           
        },
        sortDesc: true, // default sort direction
        role: Auth.getUserRole(),
    }),
    methods: {
        async getsims(newSearch = false) {      
            if (newSearch) {
                this.currentPage = 1;
            }      
            try {
                this.sims = [];
                this.progressShow = true;
                const token = localStorage.getItem("token");
                const api = process.env.VUE_APP_BASE_URL + `/sims/get_sims`;
                const query = new URLSearchParams({
                    page: this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    sortDesc: this.sortDesc ? 'desc' : 'asc',
                    search: this.search || '',
                }).toString();

                const res = await fetch(`${api}?${query}`, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.sims = jsonObject.data; // Assuming `data` is the array of customers
                    this.totalItems = jsonObject.totalItems; // Assuming `totalItems` is returned by the API
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        openEditDialog(item, index) {
            const originalItem = this.sims.find(sim => sim._id === item._id);
            this.editedItem = originalItem;
            this.formTitle = "ערוך סים"
            this.addEditDialog = true
        },
        openCreateDIalog() {
            this.formTitle = "הוסף סים חדש"
            this.editedItem = {}
            this.addEditDialog = true
        },
        openDeleteDialog(item, index) {
            this.itemToDelete = item
            this.formTitle = "האם אתה בטוח שברצונך למחוק את הסים?"
            this.alertDialog = true
        },
        newSimSaved() {
              this.debouncedGetSims()
        },
        editedSimSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.sims.findIndex(sim => sim._id === item._id);
            Object.assign(this.sims[index], item);
        },
        simDeleted(item, text, color) {
            if (!item) {
                this.showSnackBar(text, color)
                return
            }
            const index = this.sims.findIndex(sim => sim._id === item._id);
            this.sims.splice(index, 1);
            this.showSnackBar(text, color)
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        onPageChange(newPage) {
            this.currentPage = newPage;
            this.getsims(); // Fetch the new page
        },
        onItemsPerPageChange(newItemsPerPage) {
            this.itemsPerPage = newItemsPerPage;
            this.getsims(); // Fetch with updated items per page
        },
        onSortChange() {
            this.debouncedGetSims(); // Fetch with updated sorting
        },
        handleSearchInput() {
            if (this.search) {
                this.debouncedGetSims(true);  
            }else{
                 this.getsims(true)
            }
        },
        async exportSims() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/sims/export_sims_to_csv`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    // Convert response to Blob for download
                    const blob = await res.blob();
                    // Create a link element for downloading the Blob
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.download = "sims.xlsx"; // Set the desired file name
                    document.body.appendChild(link);
                    link.click(); // Programmatically click the link to trigger download
                    link.remove(); // Remove the link after download
                    // Revoke the object URL after download
                    window.URL.revokeObjectURL(downloadUrl);
                    this.showSnackBar("File downloaded successfully", "green");
                }
            } catch (error) {
                this.progressShow = false;
                console.log(error);
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
    },
    created() {
        this.getsims();

        this.debouncedGetSims = _.debounce(this.getsims, 700);

    },
}
</script>
<style>
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}

/* .v-data-table__wrapper table {
    border-collapse: collapse;
}

.v-data-table__wrapper td,
.v-data-table__wrapper th {
    border: none;
} */

/* ::v-deep table {
  border-collapse: separate !important;
  border-spacing: 0 10px!important 
} */
</style>