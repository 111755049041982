<template>
    <div>

        <div
            style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">לקוחות</span>
        </div>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px; text-align: center;">
                <v-row>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search" clearable
                            @input="handleSearchInput" class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ לקוחות
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ totalItems }}
                        </div>
                    </v-col>
                    <v-col v-if="filterType !== 'all'" cols="12" md="2"
                        style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ מנויים 
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ totalFilterManuimsCount }}
                        </div>
                        <div style="margin-right: 10px;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="primary" @click="exportFiltersManuim()">
                                        mdi-file-download-outline
                                    </v-icon>
                                </template>
                                <span>ייצוא לקובץ אקסל</span>
                            </v-tooltip>
                        </div>

                    </v-col>
                    <v-col cols="12" md="2" style="margin-top: 1%;">
                        <v-select hide-spin-buttons hide-details label="סינון לפי" flat solo :items="typesList"
                            v-model="filterType" :item-text="'hebrewName'" :item-value="'value'" @input="getCustomers"
                            class="text_fields"></v-select>
                    </v-col>
                    <v-col cols="12" md="2" style="margin-top: 1%;">
                        <v-select style="max-width: 100px;" :items="[20, 50, 100, 500, 1000]" class="text_fields"
                            v-model="itemsPerPage" hide-details flat solo label="שורות בעמוד" type="number" min="-1"
                            max="15" @input="itemsPerPage = parseInt($event, 10)"></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-data-table id="table1" :headers="headers" :items="customers" hide-default-footer
                            :server-items-length="totalItems" :items-per-page="itemsPerPage" :page.sync="currentPage"
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @update:page="onPageChange" item-key="_id"
                            @update:items-per-page="onItemsPerPageChange" @update:sort-by="onSortChange"
                            @update:sort-desc="onSortChange" show-expand :expanded.sync="expanded">

                            <template v-slot:header.index="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length"
                                    style="background-color: #f5f5f5; box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.2);">
                                    <v-row style="padding: 10px;">
                                        <v-col cols="12" md="3">
                                            <div style="background-color: white; border-radius: 15px;">
                                                <div>
                                                    <span style="font-weight: bold; color: #0d2c6d">טלפון 2</span>
                                                </div>
                                                <div>
                                                    <span>{{ item.phone2 }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div style="background-color: white; border-radius: 15px;">
                                                <div>
                                                    <span style="font-weight: bold; color: #0d2c6d">מיקוד</span>
                                                </div>
                                                <div>
                                                    <span>{{ item.postal_code }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div style="background-color: white; border-radius: 15px;">
                                                <div>
                                                    <span style="font-weight: bold; color: #0d2c6d">4 ספרות אחרונות
                                                        של
                                                        אמצעי
                                                        התשלום</span>
                                                </div>
                                                <div>
                                                    <span>{{ item.last_digits }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div style="background-color: white; border-radius: 15px;">
                                                <div>
                                                    <span style="font-weight: bold; color: #0d2c6d">נוצר
                                                        בתאריך</span>
                                                </div>
                                                <div>
                                                    <span>{{ item.createdAt }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row style="padding: 10px;">
                                        <v-col cols="12" md="3">
                                            <div style="background-color: white; border-radius: 15px;">
                                                <div>
                                                    <span style="font-weight: bold; color: #0d2c6d">רחוב</span>
                                                </div>
                                                <div>
                                                    <span>{{ item.address }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div style="background-color: white; border-radius: 15px;">
                                                <div>
                                                    <span style="font-weight: bold; color: #0d2c6d">מספר בית</span>
                                                </div>
                                                <div>
                                                    <span>{{ item.building }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </td>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <!-- Calculate the correct index based on the current page and items per page -->
                                    <!-- Assuming page is 1-based, adjust calculation if your page numbering starts from 0 -->
                                    <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                </tr>
                            </template>

                            <template v-slot:item.community_id.name="{ item }">
                                <span>{{ item.community_id.name }}</span>
                                <v-tooltip bottom v-if="role === 'admin'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-right: 17px;"
                                            @click="openEditCommunityDialog(item)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עדכון קהילה</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.actions="{ item, index }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" @click="showManium(item)"
                                            style="margin-left: 15px;">
                                            mdi-eye-outline
                                        </v-icon>
                                    </template>
                                    <span>צפייה במנויים</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="isAdmin">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openEditDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>

                                <v-icon color="rgb(207, 142, 20)"
                                    v-if="item.manuims.some(m => m.status === 'proccess')">
                                    mdi-clock-outline
                                </v-icon>

                                <span v-if="item.manuims.some(m => m.status === 'proccess')">
                                    {{ item.manuims.filter(m => m.status === 'proccess').length }}
                                </span>
                                <!-- <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium class="me-3"
                                            @click="openDeleteDialog(item, index)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip> -->
                            </template>

                            <template v-slot:no-data>
                                <v-row class="my-5">
                                    <v-col v-if="progressShow" class="d-flex justify-center">
                                        <v-progress-linear indeterminate color="#FDB721" height="6"
                                            style="width: 100%;"></v-progress-linear>
                                    </v-col>
                                </v-row>
                                <div class="ma-5">
                                    <span class="text-center"
                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא
                                        נמצאו
                                        נתונים</span>
                                </div>
                                <v-btn color="#0d2c6d" dark style="margin-bottom: 10px;" @click="getCustomers()">
                                    רענן
                                </v-btn>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" circle :length="Math.ceil(totalItems / itemsPerPage)"
                            :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            @itemEdited="editedItemSaved" />

        <EditCommunityDialog v-model="editCommunityDialog" v-if="editCommunityDialog" :itemToEdit="editedItem"
            @itemEdited="editedItemSaved" />
    </div>
</template>

<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';
import Utils from "@/util/utils";
import AddEditDialog from '@/components/customers/dialogs/AddEditDialog'
import EditCommunityDialog from '@/components/customers/dialogs/EditCommunityDialog'
import _ from 'lodash'; // Import lodash or use another debounce utility

export default {

    components: {
        SnackBar,
        AddEditDialog,
        EditCommunityDialog,
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index' },
            { text: 'שם', value: 'name' },
            { text: 'משפחה', value: 'family' },
            { text: 'ת.ז/ח.פ', value: 'card_id' },
            { text: 'שם לחשבונית', value: 'business_name' },
            { text: 'אימייל', value: 'email' },
            // { text: 'רחוב', value: 'address' },
            // { text: 'מספר בית', value: 'building' },
            { text: 'עיר', value: 'city' },
            { text: 'טלפון', value: 'phone1' },
            { text: 'קהילה', value: 'community_id.name' },
            { text: 'פעולות', value: 'actions' },
        ],
        overlay: false,
        customers: [],
        addEditDialog: false,
        editCommunityDialog: false,
        formTitle: "",
        editedItem: {},
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        progressShow: false,
        currentPage: 1,
        pageCount: 0,
        itemsPerPage: 20,
        page: 1, // Current page
        totalItems: 0, // Total number of customers for pagination controls
        totalFilterManuimsCount: 0,
        sortBy: "date_created",
        sortDesc: true, // default sort direction
        isAdmin: false,
        expanded: [],
        filterType: "all",
        typesList: [{ hebrewName: "הצג הכל", value: "all" }, { hebrewName: "מנויים בתהליך", value: "proccess" }, { hebrewName: "מנויים פעילים", value: "active" },
        { hebrewName: "מנויים לא פעילים", value: "not_active" }],
        role: Auth.getUserRole()
    }),
    computed: {
    },
    methods: {
        async getCustomers(newSearch = false) {
            if (newSearch) {
                this.currentPage = 1;
            }  
            try {
                this.progressShow = true;
                const token = localStorage.getItem("token");
                const api = process.env.VUE_APP_BASE_URL + `/customers/get_customers`;
                const query = new URLSearchParams({
                    page: this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    sortDesc: this.sortDesc ? 'desc' : 'asc',
                    search: this.search || '',
                    filterType: this.filterType
                }).toString();

                const res = await fetch(`${api}?${query}`, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;

                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.customers = jsonObject.data; // Assuming `data` is the array of customers
                    this.totalItems = jsonObject.totalItems; // Assuming `totalItems` is returned by the API
                    this.totalFilterManuimsCount = jsonObject.totalFilterManuimsCount || 0;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.customers.forEach(customer => {
                customer.createdAt = Utils.formatDateString(customer.createdAt)
            });
        },
        onPageChange(newPage) {
            this.currentPage = newPage;
            this.getCustomers(); // Fetch the new page
        },
        onItemsPerPageChange(newItemsPerPage) {
            this.itemsPerPage = newItemsPerPage;
            this.getCustomers(); // Fetch with updated items per page
        },
        onSortChange() {
            this.getCustomers(); // Fetch with updated sorting
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        showManium(customer) {
            this.$router.push({ name: "manuim", params: { id: customer._id, name: customer.name + " " + customer.family } })
        },
        openEditDialog(item, index) {
            this.editedItem = item;
            this.formTitle = "ערוך פרטי לקוח"
            this.addEditDialog = true
        },
        editedItemSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.customers.findIndex(cust => cust._id === item._id);
            Object.assign(this.customers[index], item);
        },
        openEditCommunityDialog(item) {
            this.editedItem = item;
            this.editCommunityDialog = true
        },
        async exportFiltersManuim() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/manuim/export_filter_manuim_to_excel?filterType=${this.filterType}`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    // Convert response to Blob for download
                    const blob = await res.blob();
                    // Create a link element for downloading the Blob
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.download =  this.filterType + "_Manuim.xlsx"; // Set the desired file name
                    document.body.appendChild(link);
                    link.click(); // Programmatically click the link to trigger download
                    link.remove(); // Remove the link after download
                    // Revoke the object URL after download
                    window.URL.revokeObjectURL(downloadUrl);
                    this.showSnackBar("File downloaded successfully", "green");
                }
            } catch (error) {
                this.progressShow = false;
                console.log(error);
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        handleSearchInput() {
            if (this.search) {
                this.debouncedGetCustomers(true)  
            }else{
                 this.getCustomers(true)
            }
        },
    },
    created() {
        this.getCustomers();
        this.isAdmin = Auth.getUserRole() === "admin" ? true : false;

        // Create a debounced version of getData to avoid multiple rapid calls
        this.debouncedGetCustomers = _.debounce(this.getCustomers, 700);
    },
}
</script>
<style>
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}

/* .v-data-table__wrapper table {
    border-collapse: collapse;
}

.v-data-table__wrapper td,
.v-data-table__wrapper th {
    border: none;
} */

/* ::v-deep table {
  border-collapse: separate !important;
  border-spacing: 0 10px!important 
} */
</style>