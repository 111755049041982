import ApiServices from "@/services/api.service";

class SharedFunctions {
    constructor() {
    }
  
    static async getLevels(community_id) {
      try {
        let token = localStorage.getItem("token");
        const myJSON = JSON.stringify({ community_id: community_id});
        let api = process.env.VUE_APP_BASE_URL + `/communities/get_levels`;
        const res = await fetch(api, ApiServices.requestOptions("POST", myJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return [];
        } else if (res.status === 200) {
          console.log(jsonObject);
          return jsonObject;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    }
  
    static async getCommunities() {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/communities/get_communities`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return [];
        } else if (res.status === 200) {
          console.log(jsonObject);
          return jsonObject;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    }

    static async getConstants() {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/customers/get_constants`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return [];
        } else if (res.status === 200) {
          console.log(jsonObject);
          return jsonObject;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    }

    static async getPlans() {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/plans/get_plans`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return [];
        } else if (res.status === 200) {
          console.log(jsonObject);
          return jsonObject;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    }

    static async getManuiPacks(manuiNumber) {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/manuim/get_manui_packs_from_moshe?manuiNumber=${manuiNumber}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return [];
        } else if (res.status === 200) {
          return jsonObject.pack;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    

    static async getBanksBranches(BankCode) {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/customers/get_banks_branches`;
        const res = await fetch(api, ApiServices.requestOptions("POST", JSON.stringify({ BankCode: BankCode }), token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return [];
        } else if (res.status === 200) {
          return jsonObject.result.records;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    }

    static async checkSim(simNumber) {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/customers/check_sim_not_in_use`;
        const res = await fetch(api, ApiServices.requestOptions("POST", JSON.stringify({ simNumber }), token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          console.log(2222);
          return false;
        } else if (res.status === 200) {
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    static async checkSimExistInSource(simNumber, company) {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/sims/check_sim_exist_in_source?simNumber=${simNumber}&company=${company}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return false;
        } else if (res.status === 200) {
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }
    
    
    static async getSellersList() {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/sims/get_sellers_list`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          console.log(2222);
          return false;
        } else if (res.status === 200) {
          return jsonObject;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }


  }
  export default SharedFunctions;
  
  